<template>
    <div class="cb-delete-confirm-container"> 
        <div class="cb-delete-confirm-modal">
            <div class="cb-header">
                <div class="cb-title">{{$t('frm_delete_chat')}}</div>
                <div class="cb-close" @click.prevent="handleClose"></div>
            </div>
            <div class="cb-body">
                <div class="cb-message">
                    {{ $t('frm_ai_delete_message') }}  <strong>{{ getItem('selectedChatItemForDelete').title || getItem('selectedChatItemForDelete').title_tmp }}?</strong>
                    <br>
                    <br>
                    {{ $t('frm_ai_delete_warning') }}
                </div>
            </div>
            <div class="cb-footer">
                <div class="ai-btn-cancel" @click.prevent="handleClose">{{$t('frm_cancel')}}</div>
                <div class="ai-btn-delete" @click.prevent="handleDelete">{{$t('frm_delete')}}</div>
            </div>
        </div>
    </div>
</template>
<script>
   import { createNamespacedHelpers } from 'vuex';
   const { mapActions: aiChatMapActions, mapGetters: aiChatMapGetters } = createNamespacedHelpers('aichat')
   export default {
       name: "ChatDeleteConfirmModal",
       computed: {
           ...aiChatMapGetters([
               'getItem'
           ])
       },
       methods: {
           ...aiChatMapActions([
               'setItem',
               'deleteChatHistory'
           ]),
           handleClose() {
                this.setItem({field: 'selectedChatItemForDelete', value: false});
                this.setItem({field: 'showConfirmDeleteModal', value: false});
           },
           handleDelete() {
                this.deleteChatHistory(this.getItem('selectedChatItemForDelete').id);
                this.setItem({field: 'selectedChatItemForDelete', value: false});
                this.setItem({field: 'showConfirmDeleteModal', value: false});
           }
       }
   }
</script>