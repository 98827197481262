<template>
    <div class="entries-per-page">
      <span>Show</span>
      <select :value="itemsPerPage" @change="updatePageSize($event)">
        <option v-for="option in [5, 10, 20]" :key="option" :value="option">
          {{ option }}
        </option>
      </select>
      <span>entries</span>
    </div>
  </template>

  <script>
  export default {
    props: {
      itemsPerPage: Number // Get the value from the parent
    },
    methods: {
      updatePageSize(event) {
        const newSize = Number(event.target.value);
        this.$emit("changePageSize", newSize); // Emit event to parent
      }
    }
  };
  </script>
