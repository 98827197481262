<template>
    <div class="cb-chatarea" @scroll="handleMessageScroll">
        <div class="cb-message-body">
            <Loading :active="getItem('loadChatMsgsProcessing')"
                :is-full-page="false" loader="spinner" color='black' :width="40" :height="40"
                :can-cancel="false"
                :lock-scroll="true"
                backgroundColor="none"
                :opacity="0.9"
                :z-index=999
            />
            <template v-for="(message, index) in getItem('chatMessages')">
                <div :class="['cb-message-wrapper', message.role]" :key="index">
                    <div v-if="message.content=='subscription_chat_limit_exceeded'" class="cb-message"> 
                        <div class="message-text">{{ $t('subscription_chat_limit_exceeded') }}</div>
                    </div>  
                    <div v-if="message.content=='openai_could_not_process_request'" class="cb-message"> 
                        <div class="message-text">{{ $t('openai_could_not_process_request') }}</div>
                    </div>  
                    <div v-if="message.role=='assistant'" class="cb-message">
                        <div class="message-text" v-html="getMessageWithCitation(message).content" :id="'chat-message-' + message.id" v-mathjax></div>
                        <div :class="['message-action', getItem('chatMessages').length-1 == index ? 'show' : '']">
                            <div :class="['message-like', 'ai-tooltip-bottom', (message.rating === 1)? 'active' : '']" :data-tooltip="$t('frm_ai_good_response')" @click.prevent="handleChatMessageRating(message.id, message.rating, 1)"></div>
                            <div :class="['message-dislike', 'ai-tooltip-bottom', (message.rating === 0)? 'active' : '']"  :data-tooltip="$t('frm_ai_bad_response')" @click.prevent="handleChatMessageRating(message.id, message.rating, 0)"></div>
                            <div :class="['message-copy', 'ai-tooltip-bottom',  (copiedAnswer == index) ? 'message-copied': '']" :data-tooltip="$t('frm_copy')"  @click.prevent="copyToClipboard('answer', message.content, index)"></div>
                        </div>
                    </div>
                    <div v-if="message.role=='user'" class="cb-message">
                        <div :class="['message-action']">
                            <div :class="['message-copy', 'ai-tooltip-bottom', (copiedQuestion == index) ? 'message-copied': '']" :data-tooltip="$t('frm_copy')" @click.prevent="copyToClipboard('question', message.content, index)"></div>
                        </div>
                        <div class="message-text">{{ message.content }}</div>
                    </div>
                </div>
            </template>
            <div v-if="getItem('waitingReply')" class="cb-message-wrapper assistant">
                <div class="cb-message">
                    <div class="message-text loading"></div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import Loading from 'vue-loading-overlay';
    import "@/directives/mathjaxDirective";
    import { createNamespacedHelpers } from 'vuex';
    const { mapActions: aiChatMapActions, mapGetters: aiChatMapGetters } = createNamespacedHelpers('aichat')
    export default {
        name: "ChatMessaging",
        components: {
            Loading
        },
        data() {
            return {
                messageSrollTop: 0,
                copiedAnswer: -1,
                copiedQuestion: -1
            }
        },
        computed: {
            ...aiChatMapGetters([
                'getItem'
            ]),
        },
        methods: {
            ...aiChatMapActions([
                'setItem',
                'rateChatMessage',
                'loadMoreMessages'
            ]),
            async copyToClipboard(type, content, itemIndex) {
                try {
                    if(type == 'answer') {
                        this.copiedAnswer = itemIndex;
                        this.copiedQuestion = -1;
                    }
                    if(type == 'question') {
                        this.copiedAnswer = -1;
                        this.copiedQuestion = itemIndex;
                    }
                    this.copiedResponse = itemIndex;
                    await navigator.clipboard.writeText(content);
                    setTimeout(() => {
                        this.copiedAnswer = -1;
                        this.copiedQuestion = -1;
                    }, 2000);
                } catch (error) {
                    console.error('Unable to copy to clipboard:', error);
                }
            },
            handleChatMessageRating(messageId, currentRating, rating) {
                if(currentRating === rating) {
                    rating = '';
                }
                this.rateChatMessage({messageId, rating});
            },
            getMessageWithCitation: function(msg) {
                let message = {...msg}; //create new object so that the when message content is maniupulated below,
                                        //it doesnot trigger the rerender, making it stuck in infinite loop
                if(message.citations && message.citations.length) {
                    const docId = this.getItem('docId');
                    const itemId = this.getItem('itemId');
                    const resId = this.getItem('resId');
                    const pubId = this.getItem('pubId');
                    let citations = [];
                    let messageContent = message.content;
                    messageContent.replace(/\[doc(\d)\]/g, function(match)  {
                        citations.push(match.slice(1, -1));
                        return match;
                    });
                    let citationSeqNo = 1;
                    let citationLinks = "";
                    citations = citations.sort();
                    let prevCitation = "";

                    citations.forEach(function(item) {
                        if (item != prevCitation) {
                            var replace = "\\["+item+"\\]";
                            var regex = new RegExp(replace,"g");
                            messageContent = messageContent.replace(regex, '<sup class="citation">' + citationSeqNo +'</sup>');
                            if(message.citations.length) {
                                let citationIndex = parseInt(item.replace('doc',''))-1;
                                let page = 0;
                                let decodedString = atob(message.citations[citationIndex].content);
                                let citationArray = JSON.parse(decodedString);

                                if(message.citations[citationIndex] && message.citations[citationIndex]['metadata']) {
                                    page = message.citations[citationIndex]['metadata']['page'];
                                } else if (message.citations[citationIndex] && message.citations[citationIndex]['title'] && message.citations[citationIndex]['title']['page']) {
                                    page = message.citations[citationIndex]['title']['page'];
                                }
                                page = page + 1;

                                if(citationArray.length > 0) {
                                    let strCitation = citationArray.join(' ');
                                    if(strCitation.trim() !=="") {
                                        citationLinks += ''+citationSeqNo+'&nbsp&nbsp<a class="citation-link" target="_blank" href="/management/display/citations/' + docId + '/' + itemId + '/' + resId + '/' + pubId + '/' + page + '">Page '+page+', '+ strCitation +'</a></br>';
                                    } else {
                                        citationLinks += ''+citationSeqNo+'&nbsp&nbsp<a class="citation-link" target="_blank" href="/management/display/citations/' + docId + '/' + itemId + '/' + resId + '/' + pubId + '/' + page + '">Page '+page+', ...'+'</a></br>';
                                    }
                                }
                            }
                            citationSeqNo++;
                            prevCitation=item;
                        }
                    });
                    if(citationLinks) {
                        messageContent = messageContent + "</br></br></br>References:<br>" + citationLinks;
                    }
                    // Match consecutive <sup> tags
                    const pattern = /(<sup class="citation">\d+<\/sup>)+/g;
                    // Replace them with a <div class="sup"> wrapper
                    messageContent = messageContent.replace(pattern, function(match) {
                        return '<div class="sup-citation">' + match + '</div>';
                    });
                    message.content = messageContent;
                }
                return message;
            },
            handleMessageScroll: function(el) {
                if(this.messageSrollTop == 0) {
                    this.messageSrollTop = el.srcElement.scrollTop;
                }
                if(el.srcElement.scrollTop < 20) {
                    if(this.messageSrollTop >  el.srcElement.scrollTop && !this.getItem('loadMoreMsgsProcessing')) {
                        if(this.getItem('chatMessages').length>0 && (this.getItem('chatMessages')[0].id && this.getItem('chatMessages')[0].role !='system')) {
                            if(this.getItem('chatId')) {
                                this.loadMoreMessages(this.getItem('chatId'));
                            }
                        }
                    }
                }
                this.messageSrollTop = el.srcElement.scrollTop;
            }
        }
    }
</script>