import Vue from 'vue'
import store from './store'
import i18n, {initializeLang} from './plugins/i18n'
import {setupGTM} from './plugins/gtm'
import VueCookies from 'vue-cookies'
import SearchBar from '@/components/SearchBar'
import SearchResults from '@/components/SearchResults'
import SearchResultsPWA from '@/components/SearchResultsPWA'
import VueLoading from './plugins/VueLoading'
import SearchToggle from '@/components/Nav/SearchToggle'
import QuickViews from '@/components/QuickViews'
import TourGuide from '@/components/TourGuide'
import PwaNavLeft from '@/components/Nav/PwaNav/PwaNavLeft';
import PwaNavRight from "@/components/Nav/PwaNav/PwaNavRight";
import PortalVue from 'portal-vue'
import vClickOutside from 'v-click-outside'
import VueToastr from "./plugins/VueToastr";
import Fragment from "vue-fragment";
import AIChat from '@/components/AIChat';
import AICompare from '@/components/AICompare';
import AIFaq from '@/components/AIFaq';
import VueShowdown from 'vue-showdown';
import loadMathJax from "@/directives/mathjaxDirective";


Vue.config.productionTip = false
//Vue.config.devtools = true
Vue.use(VueCookies)
Vue.use(PortalVue)
Vue.use(vClickOutside)
Vue.use(Fragment.Plugin);
Vue.use(VueShowdown);
setupGTM();
initializeLang();
loadMathJax();

const app = new Vue({
    el: '#webshop-app',
    store,
    i18n,
    VueLoading,
    VueToastr,
    components: {
        'search-bar': SearchBar,
        'search-results': SearchResults,
        'search-toggle': SearchToggle,
        'quick-views': QuickViews,
        'search-results-pwa':SearchResultsPWA,
        'pwa-nav-left': PwaNavLeft,
        'pwa-nav-right' : PwaNavRight,
        'tour-guide': TourGuide,
        'ai-chat': AIChat,
        'ai-compare': AICompare,
        'ai-faq': AIFaq,
    }
});
window.webShopVueApp = app;