<template>
    <div class="cmp-version-options-container">
        <div :class="['cmp-version-option-selected',  showOptions ? 'expanded': '']" @click.prevent="showOptions = !showOptions">
            <span>{{ itemName }}</span>
        </div>
        <div :class="['cmp-version-options', showOptions ? '' : 'hide']">
            <div v-for="item in items" :class="['cmp-version-option-item']" :key="item.item_id" @click.prevent="(event) => handleItemClick(item)">
                <span>{{ item.artefact_name }}</span>
            </div>
        </div>
    </div>
</template>
<script>
    import { createNamespacedHelpers } from 'vuex';
    const { mapActions: aiCompareMapActions, mapGetters: aiCompareMapGetters } = createNamespacedHelpers('aicompare')
    export default {
        name: "CompareTOCVersionOptions",
        props: {
            itemId: {
                type: Number,
                required: true
            },
            itemName: {
                type: String,
                required: true
            },
            items: {
                type: Array,
                required: true
            }
        },
        data: function() {
            return {
                showOptions: false
            }
        },
        computed: {
            ...aiCompareMapGetters([
                'getItem',
                'getAvailableItems'
            ])
        },
        methods: {
            ...aiCompareMapActions([
                'setItem'
            ]),
            handleItemClick(item) {
                console.log(item);
                this.showOptions = false;
                this.$emit('selectItem', item);
            }
        }
    }
</script> 