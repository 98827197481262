<template>
    <tr>
      <td class="date-column">
        <img class="img-calender" src="../../../img/ai-compare-history-calender.svg" />
        <span>{{ formatDate(item.date) }}</span>
      </td>
      <td>{{ item.version1 }}</td>
      <td>{{ item.version2 }}</td>
      <td class="result-column">
        <span @click.prevent="$emit('viewSummary', item)">View</span>
        <img @click.prevent="$emit('viewSummary', item)" class="view-image" src="../../../img/ai-compare-history-open-in-new.svg" />
      </td>
      <td class="delete-column">
        <img @click="$emit('deleteRow', item)" src="../../../img/ai-compare-history-delete.svg" />
      </td>
    </tr>
  </template>

  <script>
  export default {
    props: ["item"],
    emits: ["deleteRow", "viewSummary"],
    methods: {
      formatDate(dateString) {
        const date = new Date(dateString);
        const options = { year: 'numeric', month: 'long', day: 'numeric', timeZone: 'UTC' };
        return date.toLocaleDateString('en-US', options);
      }
    }
  };
  </script>