<template>
    <div class="cb-delete-confirm-container">
      <div class="cb-delete-confirm-modal">
        <div class="cb-header">
          <div class="cb-title">{{ $t('delete_modal.title') }}</div>
          <div class="cb-close" @click.prevent="handleClose"></div>
        </div>
        <div class="cb-body">
          <div class="cb-message">
            {{ $t('delete_modal.message') }}
          </div>
          <div class="cb-message-body">
            {{ $t('delete_modal.warning') }}
          </div>
        </div>
        <div class="cb-footer">
          <div class="ai-btn-cancel" @click.prevent="handleClose">{{ $t('delete_modal.cancel') }}</div>
          <div class="ai-btn-delete" @click.prevent="handleDelete">{{ $t('delete_modal.confirm') }}</div>
        </div>
      </div>
    </div>
  </template>

  <script>
  import { createNamespacedHelpers } from 'vuex';
  const { mapActions: aiCompareMapActions, mapGetters: aiCompareMapGetters } = createNamespacedHelpers('aicompare');

  export default {
    name: "AICompareHistoryDeleteConfirmModal",
    computed: {
      ...aiCompareMapGetters(['getItem'])
    },
    methods: {
      ...aiCompareMapActions(['setItem', 'deleteCompareHistory']),
      handleClose() {
        this.setItem({ field: 'showConfirmDeleteModal', value: false });
      },
      handleDelete() {
        this.setItem({ field: 'showConfirmDeleteModal', value: false });
        this.deleteCompareHistory(this.getItem('deleteItemId'));

        const updatedList = this.getItem('compareHistory').filter(row => row.id !== this.getItem('deleteItemId'));
        this.setItem({ field: 'compareHistory', value: updatedList });
      }
    }
  };
  </script>
