<template>
  <thead>
    <tr>
      <th
        v-for="header in headers"
        :key="header.value"
        @click="sortableColumns.includes(header.value) ? $emit('sort', header.value) : null"
        :class="{ active: sortBy === header.value, descending: sortBy === header.value && sortDesc }"
      >
        {{ header.text }}
        <span v-if="sortableColumns.includes(header.value)">
          <img src="../../../img/compare-history-th-sort-icon.svg" />
        </span>
      </th>
    </tr>
  </thead>
</template>

<script>
export default {
  props: ["headers", "sortBy", "sortDesc"],
  emits: ["sort"],
  data() {
    return {
      sortableColumns: ["date"], // Only these columns are sortable
    };
  },
};
</script>

