<template>
  <div class="table-wrapper" :class="{ 'no-border': getItem('compareHistoryTotal') === 0 }">
    <table class="custom-table">
      <!-- Table Header Always Visible -->
      <CompareHistoryTableHeader
        :headers="headers"
        :sortBy="sortBy"
        :sortDesc="sortDesc"
        @sort="$emit('sort', $event)"
      />

      <!-- Table Body: Show when there is data -->
      <tbody v-if="getItem('compareHistoryTotal') > 0">
        <CompareHistoryTableRow
          v-for="item in items"
          :key="item.date"
          :item="item"
          @viewSummary="$emit('viewSummary', item)"
          @deleteRow="$emit('deleteRow', item)"
        />
      </tbody>
    </table>

    <!-- No Data Message - Below the Table -->
    <NoHistory v-if="getItem('compareHistoryTotal') === 0" />
  </div>
</template>


  <script>
  import CompareHistoryTableHeader from "./CompareHistoryTableHeader.vue";
  import CompareHistoryTableRow from "./CompareHistoryTableRow.vue";
  import NoHistory from "./NoHistory.vue";
  import { createNamespacedHelpers } from 'vuex';

  const { mapGetters: aiCompareMapGetters } = createNamespacedHelpers('aicompare')

  export default {
    components: { CompareHistoryTableHeader, CompareHistoryTableRow, NoHistory },
    props: ["headers", "items", "sortBy", "sortDesc"],
    emits: ["sort", "deleteRow", "viewSummary"],
    computed: {
      ...aiCompareMapGetters(["getItem"]),
    }
  };
  </script>