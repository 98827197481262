<template>
    <div class="pagination">
      <button class="prev" @click="$emit('changePage', page - 1)" :disabled="page === 1"></button>
      <button v-for="p in paginationRange" :key="p" @click="$emit('changePage', p)" :class="{ active: page === p }">
        {{ p }}
      </button>
      <button class="next" @click="$emit('changePage', page + 1)" :disabled="page === pageCount"></button>
    </div>
  </template>

  <script>
  export default {
    props: ["page", "pageCount", "paginationRange"],
    emits: ["changePage"]
  };
  </script>