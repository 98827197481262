<template>
    <div class="cmp-footer">
        <div class="cmp-sub-limit">
            <div class="cmp-sub-limit-header">
                <div class="cmp-sub-limit-title">{{ $t('frm_total_sub_queries') }}</div>
                <div class="cmp-sub-limit-footer">{{ getItem('quotaAvailable')}}({{(100-quotaUsedPercentage)}}%) {{ $t('frm_remaining') }}</div>
                <div class="cmp-sub-limit-sub-title">{{  getItem('quotaUsed') }} of {{  getItem('quotaAvailable') }}</div>
            </div>
            <div class="cmp-sub-limit-quota-bar">
                <div :class="quotaClass" :style="quotaStyle">&nbsp;</div>
            </div>
        </div>    
    </div>
</template>
<script>
    import { createNamespacedHelpers } from 'vuex';
    const { mapGetters: aiCompareMapGetters } = createNamespacedHelpers('aicompare');
    export default {
        name: "ChatQuotaUsed",
        computed: {
            ...aiCompareMapGetters([
                'getItem'
            ]),
            quotaUsedPercentage() {
                const quotaAvailable = this.getItem('quotaAvailable');
                const quotaUsed = this.getItem('quotaUsed');
                const percentage = quotaAvailable === 0 ? 100 : (quotaUsed / quotaAvailable) * 100;
                return Math.round(percentage);
            },
            quotaClass() {
                let colorClass = 'green';
                const percentage = this.quotaUsedPercentage;
                if (percentage > 80) {
                    colorClass = 'red';
                }
                else if (percentage > 50) {
                    colorClass ='yellow';
                }
                return ['cmp-sub-limit-used', colorClass]
            },
            quotaStyle() {
                return {
                    width: `${this.quotaUsedPercentage}%` // Ensures width never exceeds 100%
                };
            }

        }
    }
</script>