import Vue from "vue";

const loadMathJax = () => {
    if (!window.MathJax) {
        window.MathJax = {
            startup: {
                typeset: false // Prevent auto-processing
            }
        };
        const script = document.createElement("script");
        script.type = "text/javascript";
        script.id = "MathJax-script";
        script.src = "/common/js/mathjax/3.2.2/es5/tex-chtml.js";
        script.defer = true; // Ensures it executes only after HTML is parsed
        document.head.appendChild(script);
    } 
};

export const renderMathJax = (el) => {
    if (window.MathJax) {
        window.MathJax.typesetPromise([el]);
    }
};

Vue.directive("mathjax", {
    inserted(el) {
        loadMathJax(); // Ensure MathJax is loaded before rendering
        Vue.nextTick(() => renderMathJax(el));
    },
    componentUpdated(el) {
        Vue.nextTick(() => renderMathJax(el));
    }
});

export default loadMathJax;
