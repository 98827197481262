<template>
     <div class="cb-history">
        
        <div class="cb-history-header">
            <div class="cb-header-right">
                <div class="cb-collapse-history ai-tooltip-bottom" :data-tooltip="$t('frm_ai_close_sidebar')" @click.prevent="handleHideHistory"></div>
            </div>
            <div class="cb-header-left">
                <div class="cb-history-search ai-tooltip-bottom" :data-tooltip="$t('frm_search_chat')" @click.prevent="handleOpenSearchHistory"></div>
                <div class="cb-history-new-chat ai-tooltip-bottom" :data-tooltip="$t('frm_new_chat')" @click.prevent="handleStartNewChat"></div>
            </div>
        </div>
        <div class='cb-history-body'>
            <Loading :active="getItem('loadChatHistoryProcessing')"
                :is-full-page="false" loader="spinner" color='black' :width="40" :height="40"
                :can-cancel="false"
                :lock-scroll="true"
                backgroundColor="none"
                :opacity="0.9"
                :z-index=999
            />
            <div class="cb-history-list-wrapper" @scroll="handleHistoryScroll">
                <div class="cb-no-history" v-if="Object.entries(getItem('chatHistory')).length==0 && getItem('noChatHistroy')">{{ $t('frm_no_history') }}</div>
                <div class="cb-history-list" v-for="([category, items], index) in Object.entries(getItem('chatHistory'))" :key="index">
                    <div class="cb-history-title">{{ $t(category) }}</div>
                    <div class="cb-history-items">
                        <div :class="['cb-history-item', (item.id == showActionForChatId || item.id == getItem('chatId')) ? 'active' : '']" v-for="item in items" :key="item.id">
                            <div class="cb-title">
                                <div class="cb-title-label" v-if="item.id != activeEditForChatId" @click.prevent="($event) => handleSelectChatItem($event, item)">{{ item.title || item.title_tmp}}</div>
                                <SaiInput class="sai-input" v-if="item.id == activeEditForChatId" v-model="editedTitle" @keyup.enter="($event) => handleUpdateChatTitle($event, item)" @blur="($event) => handleUpdateChatTitle($event, item)"/>
                            </div>
                            <div class="cb-title-action">
                                <div class="cb-title-action-dots" @click.prevent="($event) => handleShowActions($event, item)"></div>
                                <div v-if="item.id == showActionForChatId" class="cb-action-wrapper" v-click-outside="clickOutsideActionConfig">
                                    <div class="cb-action-item delete" @click.prevent="($event) => handleRemoveChatItem($event, item)">{{ $t('frm_delete') }}</div>
                                    <div class="cb-action-item rename" @click.prevent="($event) => handleRenameChatItem($event, item)">{{ $t('frm_rename') }}</div>
                                </div>
                            </div>
                        </div>                        
                    </div>
                </div>
            </div>
        </div>
        <ChatQuotaUsed></ChatQuotaUsed>
    </div>
</template>
<script>
    import Loading from 'vue-loading-overlay';
    import ChatQuotaUsed from './ChatQuotaUsed';
    import SaiInput from '@/components/common/SaiInput';
    import vClickOutside from 'v-click-outside';
    import { createNamespacedHelpers } from 'vuex';
    const { mapActions: aiChatMapActions, mapGetters: aiChatMapGetters } = createNamespacedHelpers('aichat')
    export default {
        name: "ChatHistory",
        components: {
            Loading,
            SaiInput,
            ChatQuotaUsed
        },
        data() {
            return {
                clickOutsideActionConfig: {
                    handler: this.clickOutSideActionHandler,
                    events: ['click']
                },
                chatHistoryScrollTop: 0,
                showActionForChatId: 0,
                activeEditForChatId: 0,
                editTitle:''
            }
        },
        directives: {
            clickOutsideAction: vClickOutside.directive
        },
        computed: {
            ...aiChatMapGetters([
                'getItem'
            ]),
        },
        methods: {
            ...aiChatMapActions([
                'setItem',
                'setUpNewChat',
                'loadMoreChatHistory',
                'deleteChatHistory',
                'updateChatTitle',
                'loadChatFromHistory'
            ]),
            handleStartNewChat() {
                let versionId = this.getItem('versionId');
                this.setUpNewChat();
                if(versionId) {
                    this.setItem({field: 'wizardStep', value: 'version-selection'});
                    this.setItem({field: 'versionId', value: versionId});
                }
                if(!this.getItem('mainChatWindowExpanded')) {
                    this.setItem({field: 'showChatHistory', value: false});
                }
            },
            handleHideHistory() {
                this.setItem({field: 'showChatHistory', value: false});
            },
            handleHistoryScroll: function(el) {
                if(this.chatHistoryScrollTop == 0) {
                    this.chatHistoryScrollTop = el.srcElement.scrollTop;
                }
                if((el.srcElement.scrollHeight - (el.srcElement.scrollTop +  el.srcElement.clientHeight)) < 10) {
                    if(this.chatHistoryScrollTop  < el.srcElement.scrollTop  && !this.getItem('loadChatHistoryProcessing')  && !this.getItem('noChatHistroy')) {
                        if(Object.keys(this.getItem('chatHistory')).length > 0 ) {
                            this.loadMoreChatHistory();
                        }
                    }

                }
                this.chatHistoryScrollTop = el.srcElement.scrollTop;
            },
            handleShowActions: function(event, selectedChatItem) {
                this.showActionForChatId = selectedChatItem.id;
            },
            clickOutSideActionHandler () {
                this.showActionForChatId = 0;
            },
            handleRemoveChatItem(event, chatItem){
                this.showActionForChatId = 0;
                this.setItem({field: 'selectedChatItemForDelete', value: chatItem});
                this.setItem({field: 'showConfirmDeleteModal', value: true});
            },
            handleRenameChatItem(event, chatItem) {
                this.showActionForChatId = 0;
                this.activeEditForChatId = chatItem.id;
                this.editedTitle = chatItem.title || chatItem.title_tmp;
            },
            handleUpdateChatTitle(event, chatItem) {
                console.log('handleUpdateChatTitle', chatItem);
                this.activeEditForChatId = 0;
                this.updateChatTitle({chatId: chatItem.id, originalTitle: chatItem.title, title: this.editedTitle});
            },
            handleSelectChatItem(event, chatItem) {
                if(this.getItem('loadChatMsgsProcessing') || this.getItem('loadMoreMsgsProcessing')) {
                    return;
                }
                this.loadChatFromHistory(chatItem);
                if(!this.getItem('mainChatWindowExpanded')) {
                    this.setItem({field: 'showChatHistory', value: false});
                }
            }, 
            handleOpenSearchHistory() {
                this.setItem({field: 'showSearchHistory', value: true});
            }

        }
    }
</script>