<template>
    <div class="no-data">
      <div class="no-history-container">
        <img src="../../../img/ai-compare-history-no-result.svg" alt="No history" class="no-history-icon" />
        <h3 class="no-history-title">No history created</h3>
        <p class="no-history-description">
          You have not created any history yet. As soon as you create a comparison, it will show up here.
        </p>
        <div class="new-compare-wrapper">
          <div class="new-compare-button"  @click.prevent="handleNewCompare">
            <img class="cmp-icon" src="../../../img/ai-chat-history-cmp-icon.svg"/>
            <span class="new-cmp">New Compare</span>
            <img class="cmp-icon-add" src="../../../img/ai-chat-cmp-plus.svg"/>
          </div>
        </div>
      </div>
    </div>
  </template>
  <script>
    import { createNamespacedHelpers } from 'vuex';

    const { mapActions: aiCompareMapActions } = createNamespacedHelpers('aicompare');

    export default {
        methods: {
        ...aiCompareMapActions(['setActivePage']),
        handleNewCompare() {
            this.setActivePage({ activeTab: 'compare-versions', tabPage: 'compare-toc' });
        }
        }
    };
  </script>
