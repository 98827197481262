<template>
  <div class="table-filters">
    <!-- Date Filter with Clickable Dropdown -->
    <div class="filter-item date-filter" v-click-outside="hideDatePicker">
      <button class="date-button" @click="toggleDatePicker">
        <img src="../../../img/ai-compare-history-calender.svg" alt="Calendar" class="calendar-icon" />
        Date <img src="../../../img/ai-compare-history-chevron-down.svg" alt="Down Arrow" class="down-arrow-icon" />
      </button>

      <!-- Date Range Picker Dropdown -->
      <div v-show="showDatePicker" class="date-dropdown">
        <p class="date-title">Date range</p>
        <div class="date-range">
          <div class="date-input">
            <label>From:</label>
            <vue2-datepicker
              class="datepicker"
              v-model="startDate"
              format="YYYY-MM-DD"
              :clearable="true"
              :disabled-date="disableFutureDates"
            />
          </div>
          <div class="date-input">
            <label>To:</label>
            <vue2-datepicker
              class="datepicker"
              v-model="endDate"
              format="YYYY-MM-DD"
              :clearable="true"
              :disabled-date="disableFutureDates"
            />
          </div>
        </div>
      </div>
    </div>

    <!-- Prior Version Select -->
    <div class="filter-item version-filter">
      <v-select
        v-model="priorVersion"
        :options="versionOptions"
        placeholder="Prior Version"
        label="label"
        class="select-filter"
        :searchable="false"
      ></v-select>
    </div>

    <!-- Newer Version Select -->
    <div class="filter-item version-filter">
      <v-select
        v-model="newerVersion"
        :options="versionOptions"
        placeholder="Newer Version"
        label="label"
        class="select-filter"
        :searchable="false"
      ></v-select>
    </div>
  </div>
</template>

<script>
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import vClickOutside from "v-click-outside";
import { createNamespacedHelpers } from 'vuex';

const { mapGetters: aiCompareMapGetters, mapActions: aiCompareMapActions } = createNamespacedHelpers('aicompare');

export default {
  name: "TableFilters",
  directives: {
    clickOutside: vClickOutside.directive,
  },
  components: {
    "vue2-datepicker": DatePicker,
    "v-select": vSelect,
  },
  data() {
    return {
      showDatePicker: false,
    };
  },
  computed: {
    ...aiCompareMapGetters(['getItem', 'getAvailableItems']),
    versionOptions() {
      return this.getAvailableItems.map(item => ({
        label: item.artefact_name,
        value: item.item_id
      }));
    },
    dateRange: {
      get() {
        const [startStr, endStr] = this.getItem('compareHistoryDateRange') || ['', ''];

        const parseDate = str =>
          str ? new Date(str.replace(/-/g, '/')) : null; // Safari-safe

        return [parseDate(startStr), parseDate(endStr)];
      },
      set(value) {
        const [start, end] = value;

        const formatDate = (date, isEnd = false) => {
          if (!date) return '';
          const d = new Date(date);
          const yyyy = d.getFullYear();
          const mm = String(d.getMonth() + 1).padStart(2, '0');
          const dd = String(d.getDate()).padStart(2, '0');
          const time = isEnd ? '23:59:59' : '00:00:00';
          return `${yyyy}-${mm}-${dd} ${time}`;
        };

        const formatted = [
          start ? formatDate(start) : '',
          end ? formatDate(end, true) : ''
        ];

        this.setItem({ field: 'compareHistoryDateRange', value: formatted });
        this.$emit('dateFilterChanged', formatted);
      }
    },
    priorVersion: {
      get() {
        return this.versionOptions.find(opt => opt.value === this.getItem('compareHistoryPriorVersion')) || null;
      },
      set(value) {
        this.setItem({ field: 'compareHistoryPriorVersion', value: value ? value.value : null });
        this.updateVersionFilter();
      }
    },
    newerVersion: {
      get() {
        return this.versionOptions.find(opt => opt.value === this.getItem('compareHistoryNewerVersion')) || null;
      },
      set(value) {
        this.setItem({ field: 'compareHistoryNewerVersion', value: value ? value.value : null });
        this.updateVersionFilter();
      }
    },
    startDate: {
      get() {
        return this.dateRange[0];
      },
      set(val) {
        const range = [...this.dateRange];
        range[0] = val;
        this.dateRange = range;
      }
    },
    endDate: {
      get() {
        return this.dateRange[1];
      },
      set(val) {
        const range = [...this.dateRange];
        range[1] = val;
        this.dateRange = range;
      }
    },
  },
  methods: {
    ...aiCompareMapActions(['setItem']),
    toggleDatePicker() {
      this.showDatePicker = !this.showDatePicker;
    },
    hideDatePicker() {
      const datepickerPopup = document.querySelector('.mx-datepicker-popup');
      if (datepickerPopup && datepickerPopup.contains(event.target)) {
        return;
      }
      this.showDatePicker = false;
    },
    updateVersionFilter() {
      this.$emit("versionFilterChanged", {
        priorVersion: this.getItem('compareHistoryPriorVersion'),
        newerVersion: this.getItem('compareHistoryNewerVersion'),
      });
    },
    disableFutureDates(date) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      return date > today;
    },
  },
};
</script>

