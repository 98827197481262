<template>
  <div class="custom-table-container" v-if="getItem('activeTab') == 'compare-history'">
    <Loading :active="getItem('compareHistoryLoading')"
        :is-full-page="false" loader="spinner"  color='#5D4266' :width="100" :height="100"
        :can-cancel="false"
        :lock-scroll="true"
        backgroundColor="white"
        :opacity="0.9"
        :z-index=999
    />
    <img :class="['cmp-loading-img', getItem('compareHistoryLoading') ? '': 'hide']" src="@/img/cmp-loading-star.svg"/>
    <CompareHistoryTableFilter @dateFilterChanged="updateDateFilter" @versionFilterChanged="applyVersionFilter"/>

    <CompareHistoryTable
      :headers="headers"
      :items="paginatedItems"
      :sortBy="sortBy"
      :sortDesc="sortDesc"
      @sort="sort"
      @deleteRow="deleteRow"
      @viewSummary="viewSummary"
    />

    <div class="table-footer" v-if="getItem('compareHistoryTotal') > 0">
      <CompareHistoryTablePageSize
        :itemsPerPage="getItem('compareHistoryItemsPerPage')"
        @changePageSize="updatePageSize"
      />
      <CompareHistoryTablePagination
        :page="getItem('compareHistoryPage')"
        :pageCount="pageCount"
        :paginationRange="paginationRange"
        @changePage="changePage"
      />
      <div class="entries-info">
        {{ offset + 1 }} - {{ Math.min(offset + getItem('compareHistoryItemsPerPage'), getItem('compareHistoryTotal')) }}
        <span>of</span> {{ getItem('compareHistoryTotal') }} <span>entries</span>
      </div>
    </div>
    <AICompareHistoryDeleteConfirmModal v-if="getItem('showConfirmDeleteModal')"/>
  </div>
</template>

<script>
import CompareHistoryTable from "./CompareHistoryTable.vue";
import CompareHistoryTableFilter from "./CompareHistoryTableFilter.vue";
import CompareHistoryTablePageSize from "./CompareHistoryTablePageSize.vue";
import CompareHistoryTablePagination from "./CompareHistoryTablePagination.vue";
import AICompareHistoryDeleteConfirmModal from "./AICompareHistoryDeleteConfirmModal.vue";
import { createNamespacedHelpers } from 'vuex';
import Loading from 'vue-loading-overlay';
import i18n from "@/plugins/i18n";

const { mapActions: aiCompareMapActions, mapGetters: aiCompareMapGetters } = createNamespacedHelpers('aicompare')

export default {
  name: "CompareHistory",
  components: {
    CompareHistoryTable,
    CompareHistoryTableFilter,
    CompareHistoryTablePageSize,
    CompareHistoryTablePagination,
    Loading,
    AICompareHistoryDeleteConfirmModal
  },
  data() {
    return {
      headers: [
        { text: i18n.t('tbl_cmpr_clm_date'), value: "date", width: "20%" },
        { text: i18n.t('tbl_cmpr_clm_pr_version'), value: "version1", width: "30%" },
        { text: i18n.t('tbl_cmpr_clm_new_version'), value: "version2", width: "30%" },
        { text: i18n.t('tbl_cmpr_clm_result'), value: "result", width: "10%" },
        { text: i18n.t('tbl_cmpr_clm_delete'), value: "delete", width: "10%" }
      ],
    };
  },
  computed: {
    offset() {
      return (this.getItem('compareHistoryPage') - 1) * this.getItem('compareHistoryItemsPerPage');
    },
    pageCount() {
      const totalRecords = this.getItem('compareHistoryTotal') || 0;
      const perPage = this.getItem('compareHistoryItemsPerPage');
      return perPage ? Math.ceil(totalRecords / perPage) : 1;
    },
    paginationRange() {
      let range = [];
      if (this.pageCount <= 5) {
        range = [...Array(this.pageCount).keys()].map(i => i + 1);
      } else {
        if (this.getItem('compareHistoryPage') <= 3) {
          range = [1, 2, 3, "...", this.pageCount];
        } else if (this.getItem('compareHistoryPage') >= this.pageCount - 2) {
          range = [1, "...", this.pageCount - 2, this.pageCount - 1, this.pageCount];
        } else {
          range = [1, "...", this.getItem('compareHistoryPage'), "...", this.pageCount];
        }
      }
      return range;
    },
    paginatedItems() {
      return this.getItem("compareHistory");
    },
    sortBy() {
        return this.getItem("compareHistorySortBy") || "date";
    },
    sortDesc() {
        return this.getItem("compareHistorySortDesc") ?? false;
    },
    ...aiCompareMapGetters(['getItem']),
  },
  methods: {
    ...aiCompareMapActions(['setItem', 'loadCompareHistory','deleteCompareHistory', 'resetCompareHistoryFilters', 'setActivePage']),
    sort(column) {
        if (!["date", "version1", "version2"].includes(column)) {
        return;
      }

      const newSortDesc = this.sortBy === column ? !this.sortDesc : false;

      this.setItem({ field: "compareHistorySortBy", value: column });
      this.setItem({ field: "compareHistorySortDesc", value: newSortDesc });

      this.loadCompareHistory();
    },
    deleteRow(item) {
      this.setItem({ field: "deleteItemId", value: item.id });
      this.setItem({ field: "showConfirmDeleteModal", value: true });
    },
    changePage(newPage) {
      if (newPage !== "..." && newPage > 0 && newPage <= this.pageCount) {
        this.setItem({ field: "compareHistoryPage", value: newPage });
        this.loadCompareHistory();
      }
    },
    updatePageSize(newSize) {
      this.setItem({ field: "compareHistoryItemsPerPage", value: newSize });
      this.setItem({ field: "compareHistoryPage", value: 1 });
      this.loadCompareHistory();
    },
    updateDateFilter(newDateRange) {
        this.setItem({ field: "compareHistoryDateRange", value: newDateRange });
        this.setItem({ field: "compareHistoryPage", value: 1 });
        this.loadCompareHistory();
    },
    applyVersionFilter(filterData) {
      this.setItem({ field: "compareHistoryPriorVersion", value: filterData.priorVersion });
      this.setItem({ field: "compareHistoryNewerVersion", value: filterData.newerVersion });
      this.setItem({ field: "compareHistoryPage", value: 1 });

      this.loadCompareHistory();
    },
    viewSummary(item) {
      console.log('viewSummary for', item);
      this.setItem({field: 'selectedRating', value: item.rating});
      this.setItem({field: 'selectedPromptId', value: item.selectedPromptId});
      this.setItem({field: 'diffSummary', value: item.diffSummary});
      this.setItem({field: 'diffSummaryId', value: item.id});
      const compareContent = item.params.compareContent.map(item => ({
        itemId: item.itemId,
        startPage: item.startPage,
        endPage: item.endPage,
        title: item.title,
        itemName: item.itemName
    }));
      this.setItem({field: 'compareParams', value: {...item.params, compareContent }});
      console.log(this.getItem('compareParams'));
      this.setActivePage({activeTab: 'compare-versions', tabPage: 'compare-summary'});
    }
  },
  mounted() {
    if (!this.getItem('compareHistoryPage')) {
      this.setItem({ field: "compareHistoryPage", value: 1 });
    }
    if (!this.getItem('compareHistoryItemsPerPage')) {
      this.setItem({ field: "compareHistoryItemsPerPage", value: 10 });
    }
    this.resetCompareHistoryFilters();
    this.loadCompareHistory();
  }
};
</script>