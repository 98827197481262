<template>
     <div class="cmp-summary-footer">
        <div :class="['cmp-rating']">
            <div class="label">{{  $t('frm_was_this_helpful') }}</div>
            <div :class="['summary-like', 'ai-tooltip-bottom', (getItem('selectedRating') == '1')? 'active' : '']" :data-tooltip="$t('frm_ai_good_response')" @click.prevent="handleSelectRating('1')"></div>
            <div :class="['summary-dislike', 'ai-tooltip-bottom', (getItem('selectedRating') == '0')? 'active' : '']"  :data-tooltip="$t('frm_ai_bad_response')" @click.prevent="handleSelectRating('0')"></div>
            <div :class="['summary-copy', 'ai-tooltip-bottom',  (copiedSummary == 1) ? 'summary-copied': '']" :data-tooltip="$t('frm_copy')"  @click.prevent="handleCopyToClipBoard"></div>
        </div>
    </div>
</template>
<script>
   
    import { createNamespacedHelpers } from 'vuex';
    const { mapActions: aiCompareMapActions, mapGetters: aiCompareMapGetters } = createNamespacedHelpers('aicompare')
    export default {
        name: "CompareSummary",
        data() {
            return {
                copiedSummary: -1
            }
        },
        computed: {
            ...aiCompareMapGetters([
                'getItem',
                'showCompareSummary',
                'showCopyButton'
            ]),
        },
        methods: {
            ...aiCompareMapActions([
                'setItem',
                'abortApiCall',
                'updateSummaryRating'
            ]),
            handleSelectRating(rating) {
                const currentRating = this.getItem('selectedRating');
                if (currentRating === rating) {
                    this.setItem({ field: 'selectedRating', value: '-1' });
                    this.updateSummaryRating({ summaryId: this.getItem('diffSummaryId'), rating: this.getItem('selectedRating') });
                } else {
                    this.setItem({ field: 'selectedRating', value: rating });
                    this.updateSummaryRating({ summaryId: this.getItem('diffSummaryId'), rating: this.getItem('selectedRating') });
                }
            },
            async handleCopyToClipBoard() {
                var copyText = this.getItem('diffSummary');
                this.copiedSummary = 1;
                await navigator.clipboard.writeText(copyText);
                setTimeout(() => {
                    this.copiedSummary = -1;
                }, 2000);
            } 
        }
    }
</script>