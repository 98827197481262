<template>
    <div class="cmp-version-footer">
        <div :class="['cmp-error-message']"><div v-html="$t(this.getItem('compareVersionsError'))"></div></div>
    </div>
</template>
<script>
    import { createNamespacedHelpers } from 'vuex';
    const { mapActions: aiCompareMapActions, mapGetters: aiCompareMapGetters } = createNamespacedHelpers('aicompare')
    export default {
        name: "CompareVresionsHeader",
        computed: {
            ...aiCompareMapGetters([
                'getItem'
            ])
        },
        methods: {
            ...aiCompareMapActions([
                'setItem',
                'abortApiCall'
            ])
        }
    }
</script>