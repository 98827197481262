<template>
    <div class="cmp-version-header">
        <div :class="['cmp-prompt-selection']">
            <div class="label">{{ $t('frm_result_format') }}:</div>
            <div class="cmp-prompt-select-wrapper">
                <Loading :active=" getItem('promptOptionsLoading')"
                    :is-full-page="false" loader="spinner" color='black' :width="20" :height="20"
                    :can-cancel="false"
                    :lock-scroll="true"
                    backgroundColor="none"
                    :opacity="0.9"
                    :z-index=999
                    />
                <SaiSelect
                    :value="getItem('selectedPromptId')"
                    :class="['compare-prompt', limitExceeded ? 'inactive': '']"
                    :disabled="limitExceeded"
                    name="compare_prompt"
                    labelBy="id"
                    trackBy="title"
                    :options="getItem('promptOptions')"
                    :placeholder="$t('frm_select_result_format')"
                    @change="handleSelectPrompt"/>
            </div>
        </div>
        <div v-if="this.getItem('tabPage') == 'compare-summary'" :class="['cmp-version-regenerate', limitExceeded ? 'inactive' : '']"  @click.prevent="handleCompare"><span>{{ $t('frm_refresh') }}</span></div>
    </div>
</template>
<script>
    import Loading from 'vue-loading-overlay';
    import SaiSelect from '@/components/common/SaiSelect';
    import { createNamespacedHelpers } from 'vuex';
    const { mapActions: aiCompareMapActions, mapGetters: aiCompareMapGetters } = createNamespacedHelpers('aicompare')
    export default {
        name: "CompareVresionsHeader",
        components: {
            SaiSelect,
            Loading
        },
        computed: {
            ...aiCompareMapGetters([
                'getItem',
                'limitExceeded'
            ])
        },
        methods: {
            ...aiCompareMapActions([
                'setItem',
                'abortApiCall',
                'getSecitonDiffSummary'
            ]),
            handleSelectPrompt(event) {
                this.setItem({field: 'selectedPromptId', value: event});
            }, 
            handleCompare() {
                if(!this.limitExceeded) {
                    this.setItem({field: 'processAborted', value: false});
                    const compareParams = this.getItem('compareParams');
                    this.setItem({field: 'compareParams', value: {...compareParams, selectedPromptId: this.getItem('selectedPromptId')}});
                    this.getSecitonDiffSummary(this.getItem('compareParams'));
                    this.setItem({field: 'selectedRating', value: -1});
                }
            }
        }
    }
</script>