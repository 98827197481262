<template>
    <div class="cmp-container">
        <div v-if="getItem('showCompareWindow')" class="cmp-box">
            <Loading :active="getItem('loading')"
                :is-full-page="false" loader="spinner"  color='#5D4266' :width="100" :height="100"
                :can-cancel="false"
                :lock-scroll="true"
                backgroundColor="white"
                :opacity="0.9"
                :z-index=999
            />
            <img :class="['cmp-loading-img', getItem('loading')?'': 'hide']" src="@/img/cmp-loading-star.svg"/>
            <div :class="['cmp-ai-analyzing', getItem('compareSummaryLoading')?'': 'hide']">{{ $t('frm_ai_analyzing') }}...</div>
            <CompareHeader></CompareHeader>
            <CompareTabs>
                <template #headerright>
                    <CompareHeaderRightCTA></CompareHeaderRightCTA>
                </template>
                <template #body>
                    <CompareVersions></CompareVersions>
                    <CompareHistory></CompareHistory>
                </template>
            </CompareTabs>
            <CompareQuotaUsed></CompareQuotaUsed>
        </div>
    </div>
</template>

<script>
    import Loading from 'vue-loading-overlay';
    import CompareHeader from './CompareHeader';
    import CompareTabs from './CompareTabs';
    import CompareVersions from './CompareVersions';
    import CompareHistory from './CompareHistory';
    import CompareHeaderRightCTA from './CompareHeaderRightCTA';
    import CompareQuotaUsed from './CompareQuotaUsed';
    import { createNamespacedHelpers } from 'vuex';
    const { mapActions: aiCompareMapActions, mapGetters: aiCompareMapGetters } = createNamespacedHelpers('aicompare')
    export default {
        name: "index",
        components: {
            Loading,
            CompareHeader,
            CompareTabs,
            CompareVersions,
            CompareHistory,
            CompareHeaderRightCTA,
            CompareQuotaUsed
        },
        computed: {
            ...aiCompareMapGetters([
                'getItem',
                'showTOCSelectoin',
                'showCompareSummary',
                'showCopyButton',
                'showBackButton',
                'showCompareButton',
                'activateCompareButton',
                'showRatingOption',
                'showRegenerateSummary'
            ])
        },
        methods: {
            ...aiCompareMapActions([
                'setItem',
                'closeCompare',
                'getSecitonDiffSummary',
                'updateSummaryRating',
                'abortApiCall'
            ])
        }
    }
</script>

<style lang="scss">
@import "aicompare";
</style>