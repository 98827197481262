<template>
    <div :class="['section-selection', getItem('tabPage') == 'compare-toc' ? '' : 'hide']">
        <div class="toc-wrapper">
            <div class="toc-title-left">
                <CompareTOCVersionOptions  :items="getItemsToCompare(getItem('language'))" :item-id="getItem('itemId1')" :item-name="getItem('itemName1')" @selectItem="handlePriorVersionClick"></CompareTOCVersionOptions>
            </div>
            <div class="toc-left">
                <TOC v-for="section in getTOC(1)" :key="section.id" :node="section" :selected-item="getItem('toc1Selection')" @titleClick="(tocItem) => handleTitleClick(tocItem, 0)"></TOC>
            </div>
        </div>
        <div class="toc-wrapper">
            <div class="toc-title-right">
                <CompareTOCVersionOptions :items="getNewVersionOptions" :item-id="getItem('itemId2')" :item-name="getItem('itemName2')" @selectItem="handleNewVersionClick"></CompareTOCVersionOptions>
            </div>
            <div class="toc-right">
                <TOC v-for="section in getTOC(2)" :key="section.id" :node="section" :selected-item="getItem('toc2Selection')" @titleClick="(tocItem) => handleTitleClick(tocItem, 1)"></TOC>
            </div>
        </div>
    </div>
</template>
<script>
    import TOC from './TOC';
    import CompareTOCVersionOptions from './CompareTOCVersionOptions';
    import { createNamespacedHelpers } from 'vuex';
    const { mapActions: aiCompareMapActions, mapGetters: aiCompareMapGetters } = createNamespacedHelpers('aicompare')
    export default {
        name: "CompareTOC",
        components: {
            TOC,
            CompareTOCVersionOptions
        },
        computed: {
            ...aiCompareMapGetters([
                'getItem',
                'getItemsToCompare'
            ]),
            showTOCSelection() {
                return this.getItem('activeTab') == 'compare-versions' && this.getItem('tabPage') == 'toc';
            },
            getNewVersionOptions() {
                // Get the selected priror version item's ID and publish_date date
                const selectedPriorVersionItemId = this.getItem('itemId1');       
                if(selectedPriorVersionItemId) {
                    const selectedLanguage = this.getItem('language');
                    const itemsToCompare = this.getItemsToCompare(selectedLanguage);
                    const priorVersionItem = itemsToCompare.filter((item) => {
                        return item.item_id == selectedPriorVersionItemId
                    });
                    const selectedPriorVersionPublishedDate = priorVersionItem[0]['publish_date'];
                    const newVersionsItemsToCompare = itemsToCompare.filter((item) => {
                        let itemPublishDate = item['publish_date'];
                        let isOlderVersion = new Date(selectedPriorVersionPublishedDate) <= new Date(itemPublishDate);
                        return selectedPriorVersionItemId.toString() === item['item_id'].toString() || isOlderVersion;
                    });
                    return newVersionsItemsToCompare;
                } else {
                    return [];
                }
            }
        },
        methods: {
            ...aiCompareMapActions([
                'setItem',
                'abortApiCall',
                'setTOCForCompare'
            ]),
            getTOCArtefactName: function(index) {
                return this.getItem('itemName' + index);
            },
            handleTitleClick(tocItem, tocIndex) {
                if(tocIndex == 0) {
                    this.setItem({field: 'toc1Selection', value: tocItem});
                }
                if(tocIndex == 1) {
                    this.setItem({field: 'toc2Selection', value: tocItem});
                }
            },
            getTOC: function(index) {
                let toc = 'toc' + index;
                return this.getItem(toc);
            },
            handleNewVersionClick: function(item) {
                this.setItem({field: 'toc2Selection', value: {}});
                this.setTOCForCompare( {versionType: 'new', itemId: item.item_id, itemName: item.artefact_name} ); //
            },
            handlePriorVersionClick: function(item) {
                this.setItem({field: 'toc1Selection', value: {}});
                this.setTOCForCompare( {versionType: 'prior', itemId: item.item_id, itemName: item.artefact_name} ); //
            }
        }
    }
</script>